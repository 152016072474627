@tailwind base;
@tailwind components;
@tailwind utilities;
@import './assets/fonts/fonts.css';

html {
    height: 100%;
    overflow-x: hidden;
}

body,
#root {
    height: 100vh;
    font-family: 'Aggro', sans-serif;
}