@font-face {
  font-family: 'Aggro';
  src: url('./aggro/SB 어그로 B.ttf') format('truetype'), url('./aggro/SB 어그로OTF B.otf') format('otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aggro';
  src: url('./aggro/SB 어그로 M.ttf') format('truetype'), url('./aggro/SB 어그로OTF M.otf') format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aggro';
  src: url('./aggro/SB 어그로 L.ttf') format('truetype'), url('./aggro/SB 어그로OTF L.otf') format('otf');
  font-weight: 300;
  font-style: normal;
}
