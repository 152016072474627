@keyframes slide-down {
    from {
        opacity: 0;
        top: 2rem;
    }
    to {
        opacity: 1;
        top: 4rem;
    }
  }
  
  @keyframes slide-up {
    from {
        opacity: 1;
        top: 4rem;
    }
    to {
        opacity: 0;
        top: 2rem;
    }
  }
  
  .slide-down {
    animation-name: slide-down;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  .slide-up {
    animation-name: slide-up;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }